<template>
  <div class="d-flex mx-4 gap align-center">
    <date-picker-menu
      data-cy="date-from"
      ref="from"
      @input="setValues"
      class="max-width"
      clearable
      :label="$t('t.From')"
      v-model="dateFrom"
    />
    <date-picker-menu
      data-cy="date-to"
      @input="setValues"
      class="max-width"
      clearable
      :label="$t('t.To')"
      :min="dateFrom"
      v-model="dateTo"
    />
    <template v-if="isLast">
      <v-btn
        v-if="dateFrom || dateTo"
        @click="$emit('add-cond')"
        icon
      >
        <v-icon>{{$icon('i.Plus')}}</v-icon>
      </v-btn>
      <div
        v-else-if="index"
        style="min-width:36px"
      />
    </template>
    <v-btn
      v-else
      @click="$emit('delete-cond')"
      icon
    >
      <v-icon>{{$icon('i.Delete')}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { Condition } from '@/pages/search/controllers/filter-param'

export default {
  components: {
    DatePickerMenu: () => import('@/components/date-picker-menu')
  },
  data () {
    return {
      dateFrom: undefined,
      dateTo: undefined
    }
  },
  methods: {
    setValues () {
      this.$emit('set-values', this.dateFrom || this.dateTo ? [this.dateFrom, this.dateTo] : [])
    }
  },
  watch: {
    isMenuOpen: {
      handler (v) {
        if (v) {
          this.$waitFor(() => this.$refs?.from).then(from => {
            if (this.isLast && !this.dateFrom && !this.dateTo) {
              from.openModal()
            }
          })
        } else {
          this.setValues()
        }
      },
      immediate: true
    },
    value: {
      handler (v) {
        [this.dateFrom, this.dateTo] = v ?? [null, null]
      },
      immediate: true
    }
  },
  props: {
    cond: Condition,
    index: Number,
    isLast: Boolean,
    isMenuOpen: Boolean,
    value: Array
  }
}
</script>

<style lang="stylus" scoped>
.max-width
  width 100%
</style>
